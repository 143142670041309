import { useState, useEffect } from 'react'

import analytics from '@mobi/libraries/analytics'
import { goTo, formatters } from 'utils'

import services from '../services'

const usePropertyDataCustomize = () => {
  const { number } = formatters
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() => {
    analytics.track('pageLoad', {
      currentPage: 'proposta/dados-imovel/customizar'
    })
  }, [])

  const onSubmit = async (payload) => {
    const { customize } = payload

    const propertyValue = number.currencyToFloat(customize)
    // business rule by loans with house as warranty
    const minPropertyValue = 53000

    analytics.track('buttonClick', {
      currentPage: 'proposta/dados-imovel/customizar',
      detail: 'click-step-dados-imovel-customizar'
    })

    if (propertyValue >= minPropertyValue) {
      setSubmitLoading(true)

      const requestBody = {
        has_property: true,
        property_value: propertyValue
      }

      return services
        .putProperty(requestBody)
        .then(({ next_step }) => goTo(next_step))
        .catch(() => setSubmitLoading(false))
    } else {
      goTo('property-data-unavailable')
    }
  }

  return { onSubmit, submitLoading }
}

export default usePropertyDataCustomize
